/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import GridComponent from './Grid'

const Post = styled.li`
  position: relative;
  border-bottom: 0px solid ${props => props.theme.colors.primary};
  border-radius: 0;
  transition: background 0.2s;
  padding: 0.25rem;
  justify-content: space-between;
  list-style: none !important;
  border-bottom: 1px solid ${props => props.theme.colors.highlight};
  flex: 0 0 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    border-bottom: none;
    flex: 0 0 100%;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 48%;
    &.two-columns {
      flex: 0 0 48%;
    }
    &.three-columns {
      flex: 0 0 32%;
    }
  }
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  p {
    font-family: ${props => props.theme.fonts.title};
    font-weight: normal;
    color: ${props => props.theme.colors.secondary};
    padding-top: 0.5rem;
  }
  a {
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font: ${props => props.theme.fonts.title};
  font-weight: 800;
  font-size: 1.4em;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 0 0 0.5rem 0;
`

const DoneBy = styled.h4`
  color: grey;
  font: ${props => props.theme.fonts.title};
  font-weight: 800;
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  tags,
  auszug,
  ...props
}) => {
  return (
    <Post>
      <Link to={`/jobs/${slug}/`}>
        <GridComponent>
          <div className="row">
            { heroImage &&
            <div className="column">
              <Img
                fluid={heroImage.fluid}
                style={{ height: 0, paddingTop: '100%' }}
                backgroundColor="#eeeeee"
              />
              </div>
            }
            <div className="double-column padded">
              <Title>{title}</Title>
              <DoneBy>
                📅
                Veröffentlicht am {publishDate}
              </DoneBy>
              <p>{auszug}</p>
              {/* tags && <TagList inline tags={tags} /> */}
            </div>
          </div>
        </GridComponent>
      </Link>
    </Post>
  )
}

export default Card
