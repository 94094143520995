/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  display: inline-flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  &.relaxed {
    margin: 2rem auto;
  }
  &::after {
    content: '';
    flex: 0 0 30%;
  }
  &.two-columns {
    justify-content: space-evenly;
    &::after {
      flex: 0 0 45%;
    }
  }
  &.three-columns {
    &::after {
      flex: 0 0 30%;
    }
  }
  &.four-columns {
    &::after {
      flex: 0 0 23%;
    }
  }
`

const CardList = props => {
  return (
    <List
      style={props.style}
      className={`${props.columns} ${props.relaxed ? 'relaxed' : ''}`}
    >
      {props.children}
    </List>
  )
}

export default CardList
