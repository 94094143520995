/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import GreenLine from '../components/GreenLine'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import TextContainer from "../components/TextContainer";
import PageBody from "../components/PageBody";

const StyledDiv = styled.div`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
`

const Jobs = ({ data, pageContext }) => {
  const jobs = data.allContentfulJob.edges
  const { body, title, subtitle } = data.contentfulPage
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Layout>
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`Jobs – ${config.siteTitle}`}</title>
        </Helmet>
      )}

      <GreenLine backgroundColor="rgba(0,0,0,0)" />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        {body && <PageBody body={body} />}
      </Container>

      <Container>

        <StyledDiv>
          <CardList relaxed>
            {jobs.map(({ node: post }) => (
              <Card key={post.id} {...post} />
            ))}
          </CardList>
        </StyledDiv>

      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: {eq: "jobs"}) {
    id
    title
    subtitle
    slug
    body {
      json
    }
    headerImage {
      fluid {
        tracedSVG
        srcWebp
        srcSetWebp
        srcSet
        src
        sizes
        aspectRatio
      }
    }
  }
  allContentfulJob (
    sort: { fields: [publishDate], order: DESC }
    filter: { node_locale: { eq: "de-CH" } }
  ) {
    edges {
      node {
        id
        startDate
        title
        publishDate(formatString: "DD.MM.YYYY")
        slug
        body {
          json
        }
        auszug
        heroImage {
          title
          fluid(quality: 100, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            aspectRatio
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
}
`
export default Jobs
